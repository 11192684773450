import { mediaHover, mediaNotMobile } from '@/src/modules/ui/styled-utils';
import styled, { css } from 'styled-components';

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 25px;
  height: 21px;

  background: #0000004d;
  border-radius: 8px;

  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.02em;
`;

type ButtonProps = {
  isFullscreen?: boolean;
  active?: boolean;
  variant?: 'comments' | 'similar';
  square?: boolean;
};

const TabButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 10px 0px 10px;

  height: 32px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.04);

  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  ${mediaNotMobile} {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: -0.025em;
  }

  color: #ffffff;

  > svg {
    height: 10px;
    width: 10px;
    padding-bottom: 1px;
  }

  ${mediaHover} {
    &:hover {
      background: rgba(255, 255, 255, 0.3);
      outline: 1px solid #ffffff0a;
    }
  }

  &:active {
    background: rgba(255, 255, 255, 0.5);
    outline: 1px solid #ffffff0a;
  }

  ${(p) =>
    p.variant === 'comments' &&
    css`
      justify-content: center;
      padding: 0px 5.5px 0px 10px;
      gap: 8px;
      min-width: 114px;
    `}

  ${(p) =>
    p.variant === 'similar' &&
    css`
      gap: 6px;
    `}

  ${(p) =>
    p.active &&
    css`
      background: #ffffff24;
      outline: 1px solid #ffffff0a;
    `}

  ${(p) =>
    p.isFullscreen &&
    css`
      background: var(--fabric-color-bg-tertiary);
      color: var(--fabric-color-text-primary);

      ${mediaHover} {
        &:hover {
          background: var(--fabric-color-bg-quarternary);
          box-shadow: 0 0 10rem 0 rgba(var(--color-bg-primary-reverse-rgb), 0.2) inset;
        }
      }

      &:active {
        background: var(--fabric-color-bg-quarternary);
      }

      ${Count} {
        background: var(--fabric-color-bg-primary);
      }

      ${p.active &&
      css`
        background: var(--fabric-color-bg-quarternary);
        outline: 1px solid #0000000a;
      `}
    `}

  ${(p) =>
    p.square &&
    css`
      aspect-ratio: 1/1;
      padding: 0;
      align-items: center;
      justify-content: center;
    `}
`;

export const ExtraPanelTabButton = Object.assign(TabButton, { Count });
