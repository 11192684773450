import { MediaContent } from '@/src/components/ExpandedFdoc/MediaContent';
import useDownloadFdoc from '@/src/hooks/useDownloadFdoc';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ResourceTitleUrlOverride } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitleUrlOverride';
import { useQueryResourceFileLikeDetails } from '@/src/modules/resource-detail/queries/useQueryResourceFileLikeDetails';
import { getResourceTitleWithDefault } from '@/src/modules/resources/utils/getResourceTitle';
import { ButtonIconDownload } from '@/src/modules/ui/components/button/ButtonIconDownload';
import { ImageFdoc } from '@/src/types/api';
import React from 'react';

export const ResourceContentImage: React.FC<{
  resource: ImageFdoc;
}> = ({ resource }) => {
  const imgRef = React.useRef<HTMLImageElement | null>(null);
  const downloadFdoc = useDownloadFdoc();

  const { data: fileLikeDetails } = useQueryResourceFileLikeDetails(resource);

  const title = getResourceTitleWithDefault(resource);

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left resource={resource}>
          <ResourceTitleUrlOverride
            resource={resource}
            resourceUrl={resource.data.pageUrl}
            title={title}
          />
        </HeaderWrapper.Left>
        <HeaderWrapper.Right>
          <ButtonIconDownload
            onClick={() => {
              downloadFdoc(resource, fileLikeDetails);
            }}
            tabIndex={4}
          />
          <ExpandedResourceDropdownMenu resource={resource} />
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <MediaContent fdoc={resource} imageRef={imgRef} disableGestures={false} />
    </>
  );
};
