import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { Flex } from '@/src/modules/ui/components/Flex';
import { P } from '@/src/modules/ui/components/Typography';

import React from 'react';
import styled from 'styled-components';

const BodyWrapper = styled(Flex).attrs({
  direction: 'column',
  gap: 12,
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
})`
  padding: 1.5rem;
`;

export const ResourceContentLoadError: React.FC = () => {
  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left resource={null}></HeaderWrapper.Left>
        <HeaderWrapper.Right />
      </HeaderWrapper>
      <BodyWrapper>
        <P>Failed to load</P>
      </BodyWrapper>
    </>
  );
};
