import clsx from 'clsx';
import React from 'react';
import styles from './LoadingOverlay.module.css';
import Spinner from './Spinner/Spinner';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  description?: string;
}

const LoadingOverlay: React.FC<Props> = ({ className, ...props }) => {
  return (
    <div className={clsx(styles['loading-overlay'], className)} {...props}>
      <Spinner color="#3654f1" thickness={3} size={50} />
    </div>
  );
};

export const LightOverlay = () => (
  <LoadingOverlay style={{ background: 'var(--fabric-color-bg-primary)' }} />
);
