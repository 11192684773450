import { ResourceContentImage } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Image/ResourceContentImage';
import { ResourceContentNotepad } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Notepad/ResourceContentNotepad';
import { ResourceContentPage } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Page/ResourceContentPage';
import { ResourceContentStoredFile } from '@/src/modules/resource-detail/components/ExpandedResource/Content/StoredFile/ResourceContentStoredFile';
import { ResourceContentText } from '@/src/modules/resource-detail/components/ExpandedResource/Content/Text/ResourceContentText';
import { ResourceHeader } from '@/src/modules/resource-detail/components/ExpandedResource/ResourceHeader';
import {
  isImageFdoc,
  isNotepadFdoc,
  isPageFdoc,
  isStoredFileFdoc,
  isTextFdoc,
} from '@/src/modules/resources/utils/resourceTypes';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

export const ResourceContent: React.FC<{ resource: OptimisticDraft<Fdoc> }> = ({ resource }) => {
  if (isNotepadFdoc(resource)) {
    return <ResourceContentNotepad resource={resource} />;
  }

  if (isPageFdoc(resource)) {
    return <ResourceContentPage resource={resource} />;
  }

  if (isTextFdoc(resource)) {
    return <ResourceContentText resource={resource} />;
  }

  if (isStoredFileFdoc(resource)) {
    return <ResourceContentStoredFile resource={resource} />;
  }

  if (isImageFdoc(resource)) {
    return <ResourceContentImage resource={resource} />;
  }

  return <ResourceHeader />;
};
