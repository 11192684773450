import { IconLinkArrow } from '@/src/modules/icons';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ResourceTitleUrlOverride } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitleUrlOverride';
import { getResourceTitleWithDefault } from '@/src/modules/resources/utils/getResourceTitle';
import { LinkExternal } from '@/src/modules/ui/components/Link/LinkExternal';
import Modal from '@/src/modules/ui/components/Modal';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { TextFdoc } from '@/src/types/api';
import { noteTextFormat } from '@/src/utils/text';
import styled from 'styled-components';

const TextWrapper = styled.div`
  padding: 1.5rem;
`;
const Text = styled.span`
  line-height: 1.6;
  color: ${cssVar['color-text-primary']};
  background: rgba(186, 206, 255, 0.3);
  box-shadow:
    0px 2.25px 0px 0px rgba(186, 206, 255, 0.3),
    0px -2.25px 0px 0px rgba(186, 206, 255, 0.3);
`;

export const ResourceContentText: React.FC<{
  resource: TextFdoc;
}> = ({ resource }) => {
  const title = getResourceTitleWithDefault(resource);

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left resource={resource}>
          <ResourceTitleUrlOverride
            resource={resource}
            resourceUrl={resource.data.pageUrl}
            title={title}
          />
        </HeaderWrapper.Left>

        <HeaderWrapper.Right>
          <Modal.IconButton as={LinkExternal} href={resource.data.pageUrl}>
            <IconLinkArrow style={{ width: 14 }} />
          </Modal.IconButton>
          <ExpandedResourceDropdownMenu resource={resource} />
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <TextWrapper>
        <Text>{noteTextFormat(resource.data.text)}</Text>
      </TextWrapper>
    </>
  );
};
