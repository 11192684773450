import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { Flex } from '@/src/modules/ui/components/Flex';

import Skeleton from '@/src/components/Skeleton/Skeleton';
import React from 'react';
import styled from 'styled-components';

const BodyWrapper = styled(Flex).attrs({
  direction: 'column',
  gap: 12,
})`
  padding: 1.5rem;
`;

export const ResourceContentSkeleton: React.FC = () => {
  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left resource={null}>
          <Skeleton borderRadius={8} width={200} height={24} />
        </HeaderWrapper.Left>
        <HeaderWrapper.Right />
      </HeaderWrapper>
      <BodyWrapper>
        <Skeleton borderRadius={8} height={300} />
        <Skeleton borderRadius={8} width={300} height={24} />
        <Skeleton borderRadius={8} width={190} height={24} />
      </BodyWrapper>
    </>
  );
};
