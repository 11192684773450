import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { useAuthUser } from '@/src/hooks/auth';
import { IconMove, IconTag, IconTrash, IconUserPlus } from '@/src/modules/icons';
import { useMutationDeleteResourcesById } from '@/src/modules/resources/mutations/useMutationDeleteResourcesById';
import { DropdownMenu } from '@/src/modules/ui/components/DropdownMenu/DropdownMenu';
import { setGlobalSelectionOptions } from '@/src/store/ui';
import { Fdoc } from '@/src/types/api';

export const ExpandedResourceDropdownMenu: React.FC<{
  resource: Fdoc;
  children?: React.ReactNode;
}> = ({ resource, children }) => {
  const { setEditTagsModalOpen, setShareModalOpen, handleOnDelete } = useExpandedFdocContext();
  const user = useAuthUser();
  const mutationDeleteResources = useMutationDeleteResourcesById();

  /**
   * in this context - can't be
   * - moved
   * - deleted
   * - sharing managed
   */
  const readOnly =
    resource.user?.id !== user?.id || resource.listData?.integration === 'GOOGLE_DRIVE';

  return (
    <DropdownMenu
      renderTriggerElement={({ onOpenChange }) => (
        <DropdownMenu.TriggerButtonThreeDots onOpenChange={onOpenChange} />
      )}
    >
      <DropdownMenu.Group>
        {children}

        <DropdownMenu.Item iconElement={<IconTag />} onClick={() => setEditTagsModalOpen(true)}>
          Add/edit tags
        </DropdownMenu.Item>
        {!readOnly && (
          <>
            <DropdownMenu.Item
              onClick={() =>
                setGlobalSelectionOptions({
                  selectedFdocsIds: [resource.id],
                  clearOnClose: true,
                })
              }
              data-testid="fdoc-add-to-space-button"
              tabIndex={5}
              iconElement={<IconMove />}
            >
              Move...
            </DropdownMenu.Item>
            <DropdownMenu.Item
              iconElement={<IconUserPlus />}
              onClick={() => setShareModalOpen(true)}
              tabIndex={5}
            >
              {resource?.isDirectShared ? 'Manage sharing' : 'Share'}
            </DropdownMenu.Item>
          </>
        )}
      </DropdownMenu.Group>

      {!readOnly && (
        <DropdownMenu.Group>
          <DropdownMenu.Item
            iconElement={<IconTrash />}
            variant="danger"
            onClick={() =>
              mutationDeleteResources.confirmAndMutate([resource.id], {
                onSuccess: () => {
                  handleOnDelete();
                },
              })
            }
            data-testid="fdoc-delete-button"
            tabIndex={5}
          >
            Delete
          </DropdownMenu.Item>
        </DropdownMenu.Group>
      )}
    </DropdownMenu>
  );
};
