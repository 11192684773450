import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import React from 'react';

import PrintIcon from '@/public/images/icons/Print.svg';
import { isInMobile } from '@/src/hooks/mobile';
import { IconLinkWindow } from '@/src/modules/icons';
import { DropdownMenu } from '@/src/modules/ui/components/DropdownMenu/DropdownMenu';

export const PDFDropdownItems: React.FC = () => {
  const { pdf } = useExpandedFdocContext();

  const iframeRef = React.useRef<HTMLIFrameElement | null>(null);
  const onPrint = async () => {
    if (!pdf) {
      return;
    }

    if (!iframeRef.current) {
      // Create an iframe
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframeRef.current = iframe;
    }

    // Load the PDF data
    const data = await pdf.getData();
    const blob = new Blob([data], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);
    iframeRef.current.src = objectUrl;

    // Wait for the PDF to load and then print it
    iframeRef.current.onload = () => {
      try {
        iframeRef.current?.contentWindow?.print();
      } catch (error) {
        console.error('Error printing PDF:', error);
      }
    };
  };

  const onOpenExternal = async () => {
    if (!pdf) {
      return;
    }
    const data = await pdf.getData();
    const blob = new Blob([data], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);

    window.open(objectUrl);

    window.URL.revokeObjectURL(objectUrl);
  };

  return isInMobile() || !pdf ? null : (
    <>
      <DropdownMenu.Item onClick={onOpenExternal} iconElement={<IconLinkWindow />}>
        Open in browser
      </DropdownMenu.Item>
      <DropdownMenu.Item iconElement={<PrintIcon />} onClick={onPrint}>
        Print
      </DropdownMenu.Item>
    </>
  );
};
