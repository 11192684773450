import { cssVar } from '@/src/modules/ui/theme/variables';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const BASE_BOTTOM_BAR_HEIGHT = `env(safe-area-inset-bottom) + 54px`;
export const BottomBar = styled(motion.div)`
  height: 1px;
  flex-shrink: 0;
  border-radius: 1.5rem 1.5rem 0 0;
  background: ${cssVar['bg-expanded-resource-extra-panel']};
  min-height: calc(${BASE_BOTTOM_BAR_HEIGHT});
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: calc(
    env(safe-area-inset-bottom) - clamp(
        0px,
        env(safe-area-inset-bottom),
        var(--keyboard-height, 0px)
      )
  );
  /** when keyboard is opened, e.g. notepad, we shirnk the content */
  max-height: calc(100dvh - var(--keyboard-height, 0px) - env(safe-area-inset-top) - 64px - 20px);
`;

export const DragHandle = styled(motion.div)`
  height: 20px;
  position: relative;
  flex-shrink: 0;
  // visible area

  &:after {
    content: '';
    display: block;
    position: absolute;
    background: red;
    width: 100%;
    height: 70px;
    top: -30px;
    /* opacity: 0.2; */
    opacity: 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${cssVar['color-text-placeholder']};
    height: 7px;
    width: 48px;
    border-radius: 3.5px;
  }
`;
