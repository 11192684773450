import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import ShareModal from '@/src/modules/ui/components/ShareModal';

export const ModalExpandedResourceShare: React.FC<{ resourceId: string }> = ({ resourceId }) => {
  const { shareModalOpen, setShareModalOpen } = useExpandedFdocContext();

  return shareModalOpen ? (
    <ShareModal
      resourceId={resourceId}
      onClose={() => setShareModalOpen(false)}
      sharingObject="item"
    />
  ) : null;
};
