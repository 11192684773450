import { Flex } from '@/src/modules/ui/components/Flex';
import Modal from '@/src/modules/ui/components/Modal';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

const HeaderWrapper = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  height: 4rem;
  padding: 1rem;
  padding-left: 1.5rem;
  border-bottom: 1px solid ${cssVar['color-border-primary']};
`;

export const ResourceHeader = () => {
  return (
    <HeaderWrapper>
      <div>Unknownfile</div>
      <Flex gap={12} alignItems="center">
        <Modal.Close variant="bg-secondary" size="sm" />
      </Flex>
    </HeaderWrapper>
  );
};
