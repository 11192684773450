import TagFilled from '@/public/images/icons/TagFilled.svg';
import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { TagButtonBase } from '@/src/components/Tags/Buttons/TagButton';
import { useQueryResourceTags } from '@/src/modules/tags/queries/useQueryResourceTags';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

const TagButtonStyled = styled(TagButtonBase)<{ offset: number }>`
  left: 1rem;
  bottom: calc(1rem + ${(p) => p.offset}px);
  z-index: 30;
`;

export const ModalExpandedResourceTagButton: React.FC<{ resourceId: string }> = ({
  resourceId,
}) => {
  const queryResourceTags = useQueryResourceTags(resourceId);

  const { setEditTagsModalOpen, toolbarHeight } = useExpandedFdocContext();

  return (
    <TagButtonStyled
      variant="bg-secondary"
      offset={toolbarHeight}
      onClick={() => {
        setEditTagsModalOpen(true);
      }}
    >
      <TagFilled
        style={{
          color: queryResourceTags.data?.length ? cssVar['color-app-primary-text'] : undefined,
        }}
      />
    </TagButtonStyled>
  );
};
