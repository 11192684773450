import { PageResource } from '@/src/modules/expandedResource/components/PageResource';
import { IconLinkArrow } from '@/src/modules/icons';
import { ExpandedResourceDropdownMenu } from '@/src/modules/resource-detail/components/ExpandedResource/components/ExpandedResourceDropdownMenu';
import { HeaderWrapper } from '@/src/modules/resource-detail/components/ExpandedResource/components/ModalResourceHeader';
import { ResourceTitleUrlOverride } from '@/src/modules/resource-detail/components/ExpandedResource/components/ResourceTitleUrlOverride';
import { getResourceTitleWithDefault } from '@/src/modules/resources/utils/getResourceTitle';
import { LinkExternal } from '@/src/modules/ui/components/Link/LinkExternal';
import Modal from '@/src/modules/ui/components/Modal';
import { PageFdoc } from '@/src/types/api';

export const ResourceContentPage: React.FC<{
  resource: PageFdoc;
}> = ({ resource }) => {
  const title = getResourceTitleWithDefault(resource);

  return (
    <>
      <HeaderWrapper>
        <HeaderWrapper.Left resource={resource}>
          <ResourceTitleUrlOverride
            resource={resource}
            resourceUrl={resource.data.pageUrl}
            title={title}
          />
        </HeaderWrapper.Left>

        <HeaderWrapper.Right>
          <Modal.IconButton as={LinkExternal} href={resource.data.pageUrl}>
            <IconLinkArrow style={{ width: 14 }} />
          </Modal.IconButton>
          <ExpandedResourceDropdownMenu resource={resource} />
        </HeaderWrapper.Right>
      </HeaderWrapper>
      <PageResource resource={resource} />
    </>
  );
};
