import StateSpinner from '@/src/modules/resources/components/StateSpinner';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import Tooltip from '@/src/ui/Tooltip';
import styled from 'styled-components';

const StyledSpinnerContainer = styled.div`
  ${mediaMobile} {
    margin-left: 0px;
    margin-right: 0;
  }
`;

export const ProcessingIndicator: React.FC<{
  resource: Pick<OptimisticDraft<Fdoc>, 'stateProcessing' | 'isDraft'>;
}> = ({ resource }) => {
  const isProcessing = isResourceStateProcessing(resource.stateProcessing) && !resource.isDraft;

  return isProcessing ? (
    <StyledSpinnerContainer>
      <Tooltip label="Waiting to be indexed and analyzed by AI">
        <StateSpinner size={22} thickness={3} isIndexing />
      </Tooltip>
    </StyledSpinnerContainer>
  ) : null;
};
