import { FC } from 'react';

interface Props {
  color: string;
}

const SimilarIconFilled: FC<Props> = ({ color }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.6">
        <path
          d="M6.95379 4.37724L8.68566 4.90433C8.78018 4.9331 8.78018 5.0669 8.68566 5.09567L6.95379 5.62276C6.316 5.81687 5.81687 6.316 5.62276 6.95379L5.09567 8.68566C5.0669 8.78018 4.9331 8.78018 4.90433 8.68566L4.37724 6.95379C4.18313 6.316 3.684 5.81687 3.04621 5.62276L1.31434 5.09567C1.21982 5.0669 1.21982 4.9331 1.31434 4.90433L3.04621 4.37724C3.684 4.18313 4.18313 3.684 4.37724 3.04621L4.90433 1.31434C4.9331 1.21982 5.0669 1.21982 5.09567 1.31434L5.62276 3.04621C5.81687 3.684 6.316 4.18313 6.95379 4.37724Z"
          fill={color}
          stroke={color}
        />
        <path
          d="M8.27082 9.41758C8.82031 9.25034 9.25034 8.82031 9.41758 8.27082C9.44236 8.18939 9.55764 8.18939 9.58242 8.27082C9.74966 8.82031 10.1797 9.25034 10.7292 9.41758C10.8106 9.44236 10.8106 9.55764 10.7292 9.58242C10.1797 9.74966 9.74966 10.1797 9.58242 10.7292C9.55764 10.8106 9.44236 10.8106 9.41758 10.7292C9.25034 10.1797 8.82031 9.74966 8.27082 9.58242C8.18939 9.55764 8.18939 9.44236 8.27082 9.41758Z"
          fill={color}
          stroke={color}
        />
      </g>
    </svg>
  );
};

export default SimilarIconFilled;
